<template>
  <div class="career">
    <div class="banner-container">
      <img class="career-img" src="../assets/image/career/career-banner.jpg" />
      <p class="join-title">Join Our Team</p>
    </div>
    <div class="information-container">
      <p class="career-title">Career at U-Pay</p>
      <div class="divider"></div>
      <div class="career-table" hover small caption-top responsive>
        <el-table
          :span-method="objectSpanMethod"
          :data="careerInformation"
          header-cell-style = "color:black;font-weight:bold; text-align:center; font-size: 1.1rem"
          border>
          <el-table-column
            prop="department"
            cell-style="font-weight:bold;"
            label="Department"
            width="250">
            <template slot-scope="scope">
                <h5 class="department-cell">{{ scope.row.department }}</h5>
              </template>
          </el-table-column>
          <el-table-column
            prop="position"
            label="Position">
              <template slot-scope="scope">
                <router-link :to="{ path: `/career/${scope.row.position.replace(/\s+/g, '_').toLowerCase()}`}">
                  <a>{{ scope.row.position }} {{ scope.row.position === 'Sale Executive' ? ' (Battambang, Siem Reap & Sihanoukville)' : '' }}</a>
                </router-link>
              </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="vacancy"
            label="Vacancy">
          </el-table-column>
          <el-table-column
            align="center"
            prop="type"
            label="Means / Refer To">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      careerInformation: [
        {
          department: 'Information Technology',
          position: 'IT Manager',
          vacancy: '1 Positions',
          type: 'Full Time'
        },
        // {
        //   department: 'Information Technology',
        //   position: 'Software Development Supervisor',
        //   vacancy: '1 Positions',
        //   type: 'Full Time'
        // },
        // {
        //   department: 'IT2',
        //   position: 'Infrastructure Supervisor',
        //   vacancy: '1 Positions',
        //   type: 'Full Time'
        // },
        // {
        //   department: 'Marketing',
        //   position: 'Marketing Officer',
        //   vacancy: '4 Positions',
        //   type: 'Full Time'
        // },
        // {
        //   department: 'Operation',
        //   position: 'Customer Service',
        //   vacancy: '4 Positions',
        //   type: 'Full Time'
        // },
        {
          department: 'Operation',
          position: 'Operation Manager',
          vacancy: '1 Positions',
          type: 'Full Time'
        },
        {
          department: 'Business Development',
          position: 'Sale Manager',
          vacancy: '1 Positions',
          type: 'Full Time'
        },
        {
          department: 'Business Development2',
          position: 'Sale Executive',
          vacancy: '4 Positions',
          type: 'Full Time'
        },
        {
          department: 'Business Development3',
          position: 'POS Machine Sale Manager',
          vacancy: '1 Positions',
          type: 'Full Time'
        },
        {
          department: 'Finance',
          position: 'Finance Manager',
          vacancy: '1 Positions',
          type: 'Full Time'
        },
        {
          department: 'Marketing',
          position: 'Marketing Manager',
          vacancy: '1 Positions',
          type: 'Full Time'
        }
      ]
    }
  },
  methods: {
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        if ( row.department === 'Information Technology' || row.department === 'Operation' || row.department === 'Marketing' || row.department === 'Finance') {
          return {
            rowspan: 1,
            colspan: 1
          }
        } else if (row.department === 'Business Development') {
          return {
            rowspan: 3,
            colspan: 1
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.banner-container {
  position: relative;
  .career-img {
    width: 100%;
  }

  .join-title {
    color: white;
    position: absolute;
    bottom: 3%;
    left: 4%;
    font-size: 2.2rem;
    font-weight: bolder;
  }
}

.information-container {
  padding: 5% 3% 5% 3%;

  .career-title {
    text-align: center;
    font-size: 2.0rem;
    margin-bottom: 1%;
  }

  .career-table {
    margin-top: 5%;

    .department-cell {
      font-weight:bolder;
    }
  }

  .divider  {
    border: 1.5px solid red;
    width: 3%;
    display: flex;
    margin: 0 auto;
  }
}
</style>
